function TitleCardDefault({
  title,
  subtitle,
  description,
  image,
  topMargin,
}) {
  return (
    <div className={`col-span-12 ${topMargin || ''}`}>
      <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-3 col-span-3 desktop:col-span-4">
        <h2 className="font-bold text-2xl mb-6">{title}</h2>
        <h2 className="font-light text-xl">{subtitle}</h2>
        <p>{description}</p>
        {image ? (
          <img
            src={image}
            alt="doctor"
            className="absolute bottom-7 right-7"
            width={160}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default TitleCardDefault;
