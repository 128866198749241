/** Icons are imported separatly to reduce build time */
import React from 'react';

import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';
import CalendarDaysIcon from '@heroicons/react/24/outline/CalendarDaysIcon';
import Cog6ToothIcon from '@heroicons/react/24/outline/Cog6ToothIcon';
import UserIcon from '@heroicons/react/24/outline/UserIcon';
import {
  CreditCardIcon,
  CurrencyDollarIcon,
  HeartIcon as HeartOutLine,
  ClipboardDocumentListIcon,
  PhotoIcon,
  WalletIcon,
  BookOpenIcon,
  FilmIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';

const iconClasses = `h-6 w-6`;
const submenuIconClasses = 'h-5 w-5';

const routesPatients = [
  {
    path: '/inicio/cli',
    icon: <Squares2X2Icon className={iconClasses} />,
    name: 'Início',
  },
  {
    path: '/especialistas/cli', // url
    icon: <CalendarDaysIcon className={iconClasses} />, // icon component
    name: 'Especialistas', // name that appear in Sidebar
  },
  {
    path: '/consultas/cli', // url
    icon: <CalendarDaysIcon className={iconClasses} />, // icon component
    name: 'Consultas', // name that appear in Sidebar
  },
  {
    path: '/favoritos/cli', // url
    icon: <HeartOutLine className={iconClasses} />, // icon component
    name: 'Meus Favoritos', // name that appear in Sidebar
  },
  {
    path: '/testes/cli', // url
    icon: <ClipboardDocumentListIcon className={iconClasses} />, // icon component
    name: 'Faça testes', // name that appear in Sidebar
  },
  {
    path: '/pagamento/cli', // url
    icon: <CreditCardIcon className={`${iconClasses} inline`} />, // icon component
    name: 'Pagamentos', // name that appear in Sidebar
    submenu: [
      {
        path: '/pagamento/cli', // url
        icon: <CurrencyDollarIcon className={submenuIconClasses} />, // icon component
        name: 'Comprar Créditos', // name that appear in Sidebar
      },
      {
        path: '/historico/cli', // url
        icon: <WalletIcon className={submenuIconClasses} />, // icon component
        name: 'Histórico Pagamentos', // name that appear in Sidebar
      },
    ],
  },
  {
    path: '/galeria/cli', // url
    icon: <PhotoIcon className={`${iconClasses} inline`} />, // icon component
    name: 'Galeria de Conteúdo', // name that appear in Sidebar
    submenu: [
      {
        path: '/galeria/cli', // url
        icon: <FilmIcon className={submenuIconClasses} />, // icon component
        name: 'Vídeos', // name that appear in Sidebar
      },
      {
        path: '/artigos/cli', // url
        icon: <BookOpenIcon className={submenuIconClasses} />, // icon component
        name: 'Artigos', // name that appear in Sidebar
      },
    ],
  },

  {
    path: '/con', // no url needed as this has submenu
    icon: <Cog6ToothIcon className={`${iconClasses} inline`} />, // icon component
    name: 'Configurações', // name that appear in Sidebar
    submenu: [
      {
        path: '/perfil/cli', // url
        icon: <UserIcon className={submenuIconClasses} />, // icon component
        name: 'Perfil', // name that appear in Sidebar
      },
    ],
  },
  {
    path: '/legal/cli', // url
    icon: <ShieldCheckIcon className={iconClasses} />, // icon component
    name: 'Legal', // name that appear in Sidebar
  },
];

export default routesPatients;
