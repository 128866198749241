import Transactions from '../../../../shared/transactions/Patient/index'


const AppointmentPatient = () => {
  return (
    <Transactions />
  )
}

export default AppointmentPatient
