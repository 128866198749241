module.exports = Object.freeze({
  MODAL_BODY_TYPES: {
    SCHEDULE: 'SCHEDULE',
    CONFIRMATION: 'CONFIRMATION',
    SPECIALIST: 'SPECIALIST',
    VACATION: 'VACATION',
    APPOINTMENT_DETAILS: 'APPOINTMENT_DETAILS',
    SUCCESS_SIGNUP: 'SUCCESS_SIGNUP',
    DEFAULT: '',
  },
});
