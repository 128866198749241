const NotFound = () => {
  const handlePath = () => {
    window.location.href = "/login"
  }
  return (
    <div className={`${window.location.pathname.split('/').includes('login') ? 'hidden' : 'flex text-center align-items justify-center m-auto'}`}>
      <div className="text-center">
        <div className="max-w-md">
          <h1 className="text-5xl  font-bold">Algo deu errado.</h1>
          <p className="text-xl mt-8">
            Sentimos muito. <br /> Não encontramos o que você procura.
          </p>
          <button
          onClick={handlePath}
            className="mt-8 text-white font-bold p-[12px] bg-[#3BBED0] border-[#3BBED0]
          border-2 rounded-[5px]"
          >
            Ir para a página principal
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
