import { useState } from 'react';
import TitleCardDefault from '../../../../../shared/components/Cards/TitleCardDefault';
import useGallery from '../../../hooks/UseGallery';

export default function GalleryArticle() {
  const { fileList } = useGallery();
  const [activeVid, setActiveVid] = useState([
    {
      id: '1Em3sMvr9AcMC1YovtlgKd275vl4zAES-',
      name: 'O que é o Autismo?',
      url: 'https://drive.google.com/file/d/1Em3sMvr9AcMC1YovtlgKd275vl4zAES-/preview',
      thumbnail:
        'https://drive.google.com/thumbnail?authuser=0&id=1Em3sMvr9AcMC1YovtlgKd275vl4zAES-',
      description: 'E a Perturbação de Espectro de Autismo?',
    },
  ]);
  return (
    <div className="card w-full p-6 grid col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault
        title="Galeria de Artigos"
        subtitle="Galeria de Conteúdos"
        description="Conheça alguns conteúdos que podem ajudar."
      />
      <div className="col-span-8 rounded-lg shadow-lg h-full p-6">
        <div className="ml-5 text-left text-xl text-slate-600">
          {activeVid[0].name}
          <p className="text-lg mt-6">{activeVid[0].description}</p>
          <p className="text-lg mt-6">
            A Perturbação do Espectro do Autismo (PEA) e o Autismo são dois
            termos genéricos que fazem parte de um grupo de complexas
            Perturbações do Neurodesenvolvimento. Esta perturbação (PEA) é
            caracterizada por dificuldades de interacção social, comunicação
            verbal e não-verbal e comportamentos de repetição. As manifestações
            desta perturbação (PEA) tendem a variar dependendo do seu grau de
            gravidade, do nível de desenvolvimento da criança e da idade da
            mesma, daí o termo espectro.
          </p>
          <h3 className="text-lg mt-6">
            Quais as características essenciais do Autismo?
          </h3>
          <p className="text-lg mt-6">
            <ul className="ml-6 list-disc">
              <li>Falta de reciprocidade social ou emocional;</li>
              <li>
                Atraso ou ausência total de desenvolvimento da linguagem oral
                (não acompanhada de tentativas de compensar através de gestos ou
                mimica);
              </li>
              <li>
                Quando existe linguagem, existe uma acentuada incapacidade para
                iniciar ou manter uma conversação com os outros;
              </li>
              <li>
                Défice no uso de comportamentos não verbais, como o contacto
                ocular, expressões faciais, posturas corporais e gestos;
              </li>
              <li>
                Défice no desenvolvimento de relações com os seus pares,
                adequadas ao seu nível de desenvolvimento;
              </li>
              <li>
                Pouca tendência espontânea para partilhar com outros prazeres,
                interesses ou objectos;
              </li>
              <li>Défice no jogo simbólico e na imitação;</li>
              <li>
                Maneirismos motores estereotipados e repetitivos (sacudir ou
                rodar as mãos, ou outros movimentos complexos);
              </li>
              <li>
                Inflexibilidade a alterações na rotina, insistem nas repetições
                e reagem negativamente perante pequenas alterações;
              </li>
              <li>
                Interesses altamente fixos e restritos de uma forma exagerada,
                quer na intensidade quer no seu objetivo (ex. podem alinhar
                vezes sem conta um numero exato de objetos, sempre da mesma
                maneira);
              </li>
              <li>
                Hiper ou Hipoactividade a estímulos externos, por exemplo,
                reação adversa a sons ou texturas específicas, fascínio por
                luzes ou movimentos, podem tocar ou cheirar de forma exagerada
                determinados objetos e ter uma aparente indiferença à dor ou à
                temperatura.
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div className="col-span-4">
        <div className="relative shadow-lg p-5 flex flex-col border-slate-200 rounded-lg h-[800px]">
          <div className="bg-[#3bbdcf] text-white absolute top-0 left-0 rounded-t-2xl w-full">
            <h3 className="text-lg p-3 ml-3 font-semibold">Lista de Artigos</h3>
          </div>
          <div
            className="overflow-y-auto relative top-12"
            style={{ height: 'min(650px)' }}
          >
            {activeVid?.map((e, index) => {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  role="button"
                  tabIndex={index}
                  title="Clique para Assitir"
                  className="hover:bg-gray-300 p-2 border-2 rounded-xl shadow-xl shadow-gray-300"
                >
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="float-left align-middle mr-5 w-[168px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
                      />
                    </svg>

                    <div className="table-cell align-middle">
                      <p className="font-semibold text-base">{e.name}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
