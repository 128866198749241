/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import Route from './Route';

import { Dashboard, Appointment } from '../pages';
import Profile from '../pages/Settings/Profile';
import Legal from '../../../shared/pages/legal';
import CancelMeet from '../../../shared/pages/Cancel';

// eslint-disable-next-line react/function-component-definition
const Routes = () => (
  <>
    <Route path="/inicio/pro" component={Dashboard} isPrivate />
    <Route path="/Agendamentos/pro" component={Appointment} isPrivate />
    <Route path="/consulta-cancelada/pro" component={CancelMeet} isPrivate />

    <Route path="/perfil/pro" component={Profile} isPrivate />
    <Route path="/legal/cli" component={Legal} isPrivate />
  </>
);

export default Routes;
