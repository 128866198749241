import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../../data/supabase';

const getAppointmentsPatientadmin = async () => {
   return supabase
    .from('appointment')
    .select(
      `
    id,
    start,
    end,
    status,
    specialist ( name, email ),
    patient ( name, email ),
    created_at,
    category,
    meetLink
  `,
    )
};

function useAppoitmentsPatientADMIN() {
  const queryKey = ['appoitment'];

  const queryFn = async () => {
    return getAppointmentsPatientadmin().then((result) => {
      return result.data
    });
  };

  return useQuery({ queryKey, queryFn });
}

export default useAppoitmentsPatientADMIN;
