
const ButtonSchedule = ({ children, color, handleClick }) => {

  return (
    <button
    onClick={handleClick}
    // className={styles.buttonActive}
    className={`${color} rounded-md p-[18px]`}
  >

    {children}
  </button>
  )
}

export default ButtonSchedule
