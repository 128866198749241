/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route as ReactDOMRoute, Redirect } from "react-router-dom";

// import { useAuth } from "@shared/hooks/auth";

function Route({ isPrivate = false, component: Component, ...rest }) {
  const user = {
    type: 1,
  };
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) =>
        isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/login" : "/cadastrar",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Route;
