import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../../data/supabase';

const getAppointmentsPatient = async (patient) => {
  return supabase
    .from('appointment')
    .select(
      `
    id,
    start,
    end,
    start_history,
    end_history,
    status,
    specialist ( id, name ),
    category,
    meetLink
  `,
    )
    .eq('patient_id', patient);
};



function useAppoitmentsPatient(id) {
  const queryKey = ['appoitments', id];

  const queryFn = async () => {
    return getAppointmentsPatient(id).then((result) => {
      return result.data;
    });
  };

  return useQuery({ queryKey, queryFn });
}


export default useAppoitmentsPatient;
