
function ModalSpecialist({ extraObject, closeModal }) {
  const { message, avatar_url, about } = extraObject;
  return (
    <>
      <img
        alt="imageProfile"
        className="inline-block h-20 w-20 rounded-full "
        src={avatar_url ?? `https://ui-avatars.com/api/?name=${message}`}
      />
      <div className="grid ml-2">
        <h4 className="text-[#0E0E2C] dark:text-white text-[22px] font-work mb-1 mt-2 font-medium">
          {message}
        </h4>
        <div className="divider"> </div>
        <p>{about}</p>
      </div>
    </>
  );
}

export default ModalSpecialist;
