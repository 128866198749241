import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { supabase } from '../../data/supabase';
import { getUser } from '../../store/reducers/user';

const AuthContext = createContext({
  session: null,
  user: null,
  signOut: () => {},
});

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [session, setSession] = useState();
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState();
  const [typeUser, setTypeUser] = useState();
  const [pathPhotoAvatar, setPathPhotoAvatar] = useState();
  const [patient, setPatient] = useState([]);
  const [specialist, setSpecialist] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const setData = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error) throw error;
      setSession(session);
      setUser(session?.user);
      setLoading(false);
    };

    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
        setUser(session?.user);
        setLoading(false);
      },
    );
    setData();

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const typeUser = window.location.pathname.split('/')[2];
    dispatch(getUser({ role: typeUser }));
    const setType = async () => {
      const { data } = await supabase
        .from(
          typeUser === 'cli'
            ? 'patient'
            : typeUser === 'pro'
              ? 'specialist'
              : 'admin',
        )
        .select('role');
      setUserType(data[0]?.role);
    };

    setTypeUser(typeUser);
    setType();
  }, []);

  useEffect(() => {
    const getAvatar = async () => {
      if (session) {
        const { data } = await supabase.storage
          .from('uploads')
          .list(`${session?.user?.id}/avatar/`, {
            limit: 1,
            offset: 0,
            sortBy: { column: 'updated_at', order: 'desc' },
          });

        if (data && data?.length > 0) {
          setPathPhotoAvatar(data[0].name);
        }
      }
    };

    const getProfile = async () => {
      const { data } = await supabase
        .from('patient')
        .select()
        .eq('id_user', session?.user?.id);
      if (data) {
        setPatient(data);
      }
    };

    const getProfilePro = async () => {
      const { data } = await supabase
        .from('specialist')
        .select()
        .eq('id_user', session?.user?.id);
      if (data) {
        setSpecialist(data);
      }
    };

    getProfile();
    getProfilePro();
    getAvatar();
  }, [session, setPathPhotoAvatar]);

  const getURL = () => {
    if (process.env.NODE_ENV === 'development') {
      return `http://localhost:3000/inicio/${typeUser}`;
    }

    return `https://app.embraceinternacional.com/inicio/${typeUser}`;
  };

  async function signInWithGoogle() {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: getURL(),
          scopes:
            'https://www.googleapis.com/auth/calendar.events, https://www.googleapis.com/auth/calendar.events',
        },
      });

      if (error)
        throw new Error('A ocurrido un error durante la autenticación');
      // toast.success("Faça Login")
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function signUpWithGoogle() {
    try {
      const { data } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: getURL(),
          scopes:
            'https://www.googleapis.com/auth/calendar.events, https://www.googleapis.com/auth/calendar.events',
        },
      });

      axios.post(
        'https://webhook.sellflux.app/webhook/custom/lead/9f4a44a16dec1a082e77acf25f2a5992',
        {
          params: {
            name: '',
            phone: user?.phone,
            email: user?.email,
          },
        },
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  }

  const value = {
    session,
    signInWithGoogle,
    signUpWithGoogle,
    userType,
    typeUser,
    user,
    patient,
    specialist,
    pathPhotoAvatar,
    signOut: () => supabase.auth.signOut(),
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
