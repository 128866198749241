import { toast } from 'react-toastify';
import { supabase } from '../../data/supabase';

function useConsent() {
  const saveTermsOfUse = async (consentLog) => {
    try {
      await supabase
        .from('consent_logs')
        .insert({
          terms_of_use: consentLog.termsOfUse,
          email: consentLog.email,
        })
        .select();
    } catch (error) {
      toast.error(
        'Erro ao cadastrar dados de cadastro na tabela consent_logs!',
      );
    }
  };

  return {
    saveTermsOfUse,
  };
}

export default useConsent;
