import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    role: '',

  },
  reducers: {
    getUser: (state, action) => {
      const { role } = action.payload;
      state.role = role;
    },
  },
});

export const { getUser } = userSlice.actions;

export default userSlice.reducer;
