import { useEffect, useState } from 'react';
import axios from 'axios';

function useGallery() {
  const [fileList, setFileList] = useState([]);

  const getFileList = async () => {
    axios
      .get('https://app-back-embrace.vercel.app/api/files-list')
      .then((response) => setFileList(response.data.files))
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(fileList);

  useEffect(() => {
    getFileList();
  }, []);

  return {
    fileList,
  };
}

export default useGallery;
