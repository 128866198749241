/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import Route from './Route';

import {
  Dashboard,
  Specialists,
  Favorite,
  AppointmentPatient,
  Tests,
} from '../pages';
import PatientProfile from '../pages/Settings/Profile';
// import NotFound from '../../../shared/pages/NotFound';

import Payment from '../pages/payment';
import Cancel from '../pages/payment/StripeCheckout/Cancel/Cancel';
import Success from '../pages/payment/StripeCheckout/Success/Success';
import CancelMeet from '../../../shared/pages/Cancel'
import Billing from '../pages/payment/Billing';
import GalleryVideo from '../pages/Gallery/Video';
import GalleryArticle from '../pages/Gallery/Article';
import Legal from '../../../shared/pages/legal';

const Routes = () => (
  <>
    <Route path="/inicio/cli" component={Dashboard} isPrivate />
    <Route path="/especialistas/cli" component={Specialists} isPrivate />
    <Route path="/testes/cli" component={Tests} isPrivate />
    <Route path="/consultas/cli" component={AppointmentPatient} isPrivate />
    <Route path="/favoritos/cli" component={Favorite} isPrivate />
    <Route path="/perfil/cli" component={PatientProfile} isPrivate />
    {/* <Route path="*" component={NotFound} /> */}
    <Route path="/pagamento/cli" component={Payment} isPrivate />
    <Route path="/sucesso/cli" component={Success} isPrivate />
    <Route path="/cancelado/cli" component={Cancel} isPrivate />
    <Route path="/historico/cli" component={Billing} isPrivate />
    <Route path="/consulta-cancelada/cli" component={CancelMeet} isPrivate />
    <Route path="/galeria/cli" component={GalleryVideo} isPrivate />
    <Route path="/artigos/cli" component={GalleryArticle} isPrivate />
    <Route path="/legal/cli" component={Legal} isPrivate />
  </>
);

export default Routes;
