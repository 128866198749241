import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../../data/supabase';

const getAppointmentsSpec = async (specialist) => {
  return supabase
    .from('appointment')
    .select(
      `
    id,
    start,
    end,
    start_history,
    end_history,
    status,
    patient ( id, name ),
    category,
    meetLink
  `,
    )
    .eq('specialist_id', specialist);
};

function useAppoitmentsPro(id) {
  const queryKey = ['appoitments', id];

  const queryFn = async () => {
    return getAppointmentsSpec(id).then((result) => {
      return result.data;
    });
  };

  return useQuery({ queryKey, queryFn });
}

export default useAppoitmentsPro;
