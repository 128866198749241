import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { supabase } from '../../../../data/supabase';
import { useAuth } from '../../../../shared/context/useAuth';
import { openModal } from '../../../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../../../utils/globalConstantUtil';
import HorizontalCalendar from '../../pages/Specialists/HorizontalCalendar';

function Image({ userId, sourceImage, name }) {
  const [actualSrc, setActualSrc] = useState('placeholder image link');

  useEffect(() => {
    const imageProFun = async () => {
      const { data } = await supabase.storage
        .from('uploads')
        .list(`${userId}/avatar`, {
          offset: 0,
          sortBy: {
            column: 'created_at',
            order: 'desc',
          },
        });

      if (data) {
        const result = `https://data.embraceinternacional.com/storage/v1/object/public/uploads/${userId}/avatar/${data[0]?.name}`;

        return result;
      }
      return null;
    };

    imageProFun(userId).then((url) => setActualSrc(url));
  }, [userId]);

  let imagePath;

  if (actualSrc?.split('/')[10] === '.emptyFolderPlaceholder') {
    imagePath = `https://ui-avatars.com/api/?name=${name}`;
  }
  if (actualSrc?.split('/')[10] !== '.emptyFolderPlaceholder') {
    imagePath = actualSrc;
  }

  if (sourceImage) {
    imagePath = sourceImage;
  }
  return (
    <img
      src={imagePath}
      className="inline-block h-20 w-20 rounded-full"
      alt=""
    />
  );
}

const CardSpecialist = ({ specialistData, setFavoritesPatient = () => {} }) => {
  const dispatch = useDispatch();
  const { patient } = useAuth();

  const [schedules, setSchedule] = useState();
  const [fetchError, setFetchError] = useState();
  const [allFavorites, setAllFavorites] = useState();

  useEffect(() => {
    const getFavorites = async () => {
      const { data: patient_favorite } = await supabase
        .from('patient_favorite')
        .select('*')
        .eq('id_patient', patient[0]?.id)
        .order('id', { ascending: false })
        .limit(1);

      if (patient_favorite) {
        setAllFavorites(patient_favorite[0]?.list_favorite);
      }
    };

    getFavorites();
  }, [JSON.stringify(specialistData)]);

  // TODO - Corrigir o ID
  const idSpec = specialistData?.map((spec) => spec?.id);

  const isFavorite = specialistData?.map((item) => item?.isFavorite);

  async function addFavoriteSpec(id) {
    if (allFavorites?.length <= 0 || allFavorites === undefined) {
      await supabase
        .from('patient_favorite')
        // eslint-disable-next-line no-unsafe-optional-chaining
        .insert([
          {
            id_patient: patient[0]?.id,
            list_favorite: [{ id, isFavorite: true }],
          },
        ])
        .eq('id_patient', patient[0]?.id)
        .select();
    }

    const hasFav = allFavorites?.some((item) => item?.id === id);

    if (hasFav) {
      toast.warn('Especialista já adicionado!');
    }

    if (allFavorites?.length > 0) {
      await supabase
        .from('patient_favorite')
        // eslint-disable-next-line no-unsafe-optional-chaining
        .update({
          list_favorite: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...allFavorites,
            { id, isFavorite: true },
          ],
        })
        .eq('id_patient', patient[0]?.id)
        .select();
    }
    if (!hasFav) {
      toast.success('Especialista adicionado aos favoritos!!');
    }
  }

  useEffect(() => {
    const listFavorite = async () => {
      const { data: patients, error } = await supabase
        .from('patient_favorite')
        .select('list_favorite')
        .eq('id_patient', patient[0]?.id)
        .order('id', { ascending: false })
        .limit(1);

      if (patients) {
        setFavoritesPatient(patients[0]?.list_favorite);
      }

      if (error) {
        toast.error(error);
      }
    };

    listFavorite();
  }, [JSON.stringify(patient), JSON.stringify(allFavorites)]);

  async function removeFavorite(id) {
    const resultRemove = allFavorites?.filter((item) => {
      return item.id !== id;
    });

    const { data, error } = await supabase
      .from('patient_favorite')
      // eslint-disable-next-line no-unsafe-optional-chaining
      .update({
        list_favorite: resultRemove,
      })
      .eq('id_patient', patient[0]?.id)
      .select();
    if (data) {
      setFavoritesPatient(data[0]?.list_favorite);
    }
    // TODO - ajustar para nao precisar fazer refresh na tela
    window.location.reload();

    if (!error) {
      toast.success('Especialista removido com sucesso!');
    }
  }

  useEffect(() => {
    const fetchSchedule = async () => {
      const { data, error } = await supabase
        .from('schedule')
        .select()
        .in('specialist_id', idSpec);

      if (error) {
        fetchError(error);
        setSchedule([]);
      }

      if (data) {
        setSchedule(data);
        setFetchError(null);
      }
    };
    fetchSchedule();
  }, [idSpec?.length > 0]);

  const [listSpecialists, setListSpecialists] = useState([]);
  useEffect(() => {
    async function fetchSpecialists() {
      const { data, error } = await supabase.from('specialist').select('*');

      if (error) {
        setListSpecialists([]);
      }

      if (data) {
        setListSpecialists(data);
      }
    }

    fetchSpecialists();
  }, []);

  const mergeData = schedules?.map((item) => {
    return {
      ...item,
      ...listSpecialists?.find((item2) => item2.id === item.specialist_id),
    };
  });

  function modalSpecialist(name, avatar_url, skills, about) {
    dispatch(
      openModal({
        bodyType: MODAL_BODY_TYPES.SPECIALIST,
        extraObject: { message: name, avatar_url, skills, about },
      }),
    );
  }

  const filteredArr = mergeData?.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    }
    return acc
  }, []);

  return (
    <>
      {fetchError && <p>{fetchError}</p>}
      {schedules &&
        filteredArr?.map((item) => {
          return (
            <>
              {item?.allow && (
                <>
                  <div className="bg-white dark:text-white dark:bg-base-100 rounded-3xl desktop:p-[73px] p-[52px] mb-2 desktop:mb-0 laptop:col-span-6 col-span-12 desktop:col-span-3">
                    <div className="flex align-center mb-8">
                      <Image
                        sourceImage={item?.avatar_url}
                        userId={item?.id_user}
                        name={item?.name}
                      />
                      <div className=" ml-2">
                        <h4
                          onClick={() =>
                            modalSpecialist(
                              item.name,
                              item.avatar_url,
                              item.skills,
                              item.about,
                            )
                          }
                          className="text-[#0E0E2C] cursor-pointer dark:text-white text-[22px] font-work font-medium"
                        >
                          {item.name}
                        </h4>
                        {!isFavorite[0] && (
                  <button
                    onClick={() => addFavoriteSpec(item.id)}
                    className="btn mt-4 capitalize btn-xs bg-[#3BBED0] hover:bg-[#3BBED0] border-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                    adicionar aos favoritos
                  </button>
                )}
                {isFavorite[0] && (
                  <button
                    onClick={() => removeFavorite(item.id)}
                    className="btn ml-2 mt-4 capitalize btn-xs bg-error hover:bg-error border-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    remover
                  </button>
                )}
                      </div>

                    </div>
                    <div className="mb-12 font-thin">{item?.about}</div>
                    <span className="text-[#818181] font-bold">
                      Area de atendimento:
                    </span>

                    <div className="mt-4 flex flex-wrap gap-1">
                      {item?.skills?.map((item) => {
                        return (
                          <div className="bg-[#ECEDF0] rounded-[100px]  px-3 items-center">
                            <span className="text-[#818181] font-semibold  text-sm ">
                              {item.label.toLowerCase()}
                            </span>
                          </div>
                        );
                      })}
                    </div>

                  </div>
                  <div
                    className={`dark:text-white ${item?.skills?.length >= 5 ? 'py-24' : 'py-4'} bg-white dark:bg-base-100 rounded-3xl px-2  justify-center items-center laptop:col-span-6 col-span-12 desktop:col-span-3`}
                  >
                    <HorizontalCalendar
                      nameSpec={item.name}
                      emailSpec={item.email}
                      schedules={item.schedule}
                      idSpec={item.id}
                      allData={mergeData}
                    />
                  </div>
                </>
              )}
            </>
          );
        })}
    </>
  );
};

export default CardSpecialist;
