/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { FloatingWhatsApp } from 'react-floating-whatsapp';
import RouterAdmin from '../../modules/admin/routes';
import RoutePatients from '../../modules/patients/routes';
import RouterSpecialists from '../../modules/specialist/routes';
import { useAuth } from '../context/useAuth';
import LayoutWrapper from '../pages/components/LayoutWrapper';
import NotFound from '../pages/NotFound';
import ResetPassword from '../pages/ResetPassword';
import { Sucess } from '../pages/Sucess';
import PublicRoutes from './PublicRoutes';

import pdfEmergency from '../legalDocs/emergencyandcrisis.pdf';
import pdfConsent from '../legalDocs/informedconsent.pdf';
import pdfLegal from '../legalDocs/legal.pdf';
import pdfPolicy from '../legalDocs/privacypolicy.pdf';
import pdfTerms from '../legalDocs/termsofuse.pdf';

const Legal = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfLegal}>
      {' '}
    </iframe>
  );
};

const PrivacyPolicy = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfPolicy}>
      {' '}
    </iframe>
  );
};

const TermsOfUse = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfTerms}>
      {' '}
    </iframe>
  );
};

const InformedConsent = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfConsent}>
      {' '}
    </iframe>
  );
};

const EmergencyAnCrisis = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfEmergency}>
      {' '}
    </iframe>
  );
};

function Routes() {
  const { user, userType } = useAuth();
  const mainContentRef = useRef(null);
  useEffect(() => {
    mainContentRef?.current?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="drawer">
      <BrowserRouter>
        <FloatingWhatsApp
          accountName="Embrace Internacional"
          allowClickAway
          allowEsc
          avatar="/newlogo.png"
          messageDelay={1}
          statusMessage="Responderemos o mais breve possível"
          chatMessage="Como podemos te ajudar?"
          phoneNumber="+16177024662"
        />
        <Switch>
          <Route path="/legal.pdf" exact component={Legal} />
          <Route
            path="/legal/emergencyandcrisis.pdf"
            exact
            component={EmergencyAnCrisis}
          />
          <Route
            path="/legal/privacypolicy.pdf"
            exact
            component={PrivacyPolicy}
          />
          <Route path="/legal/termsofuse.pdf" exact component={TermsOfUse} />
          <Route
            path="/legal/informedconsent.pdf"
            exact
            component={InformedConsent}
          />
          {!user && <PublicRoutes />}
          {/* <Route path="*" component={NotFound} /> */}
          <Route path="/sucess/cli" exact component={Sucess} />
          <Route path="/sucess/pro" exact component={Sucess} />
          <Route path="/mudar-senha/pro" exact component={ResetPassword} />
          <Route path="/mudar-senha/cli" exact component={ResetPassword} />

          <LayoutWrapper>
            {(user && userType === 'cli' && <RoutePatients />) ||
              (user && user?.user_metadata?.role === 'cli' && (
                <RoutePatients />
              ))}
            {(user && userType === 'pro' && <RouterSpecialists />) ||
              (user && user?.user_metadata?.role === 'pro' && (
                <RouterSpecialists />
              ))}
            {user && userType === 'admin' && <RouterAdmin />}
          </LayoutWrapper>
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default Routes;
