import { configureStore } from "@reduxjs/toolkit";
import modalSlice from "../store/reducers/modal";
import favoriteSlice from '../store/reducers/favorite';
import userSlice from '../store/reducers/user';
import avatarSlice from '../store/reducers/avatar'

const store =  configureStore({
  reducer: {
    favorite: favoriteSlice,
    modal: modalSlice,
    avatar: avatarSlice,
    user: userSlice,
  }
});

export default store;
