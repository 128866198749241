import moment from 'moment';

function VacationModalBody({ extraObject, closeModal }) {
  const { title, start, end, patient, status, meetLink } = extraObject;

  const handleSubmitConfirm = () => {
    closeModal();
  };

  const handleStatus = (status) => {
    const sts = { value: '', color: '' };
    switch (status) {
      case 'confirmed':
        sts.value = 'Confirmado';
        sts.color = '#3788d8';
        break;
      case 'pending':
        sts.value = 'Pendente';
        sts.color = '#ffc107';
        break;
      case 'canceled':
        sts.value = 'Cancelado';
        sts.color = '#dc3545';
        break;
      default:
        break;
    }
    return sts;
  };

  return (
    <>
      <h1 className="text-xl mb-4">{`Paciente: ${patient}`}</h1>
      <div className="mb-4">
        <div
          className={`inline-flex items-center me-2 text-[${
            handleStatus(status).color
          }]`}
        >
          <span className="text-black me-2">Status:</span>{' '}
          {handleStatus(status).value}
        </div>
      </div>
      <div className="mb-4" />
      <a
        href={meetLink}
        target="_blank"
        rel="noreferrer"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 211"
          className="w-6 h-6 mr-2"
        >
          <path
            fill="#00832D"
            d="m144.822 105.322l24.957 28.527l33.562 21.445l5.838-49.792l-5.838-48.669l-34.205 18.839z"
          />
          <path
            fill="#0066DA"
            d="M0 150.66v42.43c0 9.688 7.864 17.554 17.554 17.554h42.43l8.786-32.059l-8.786-27.925l-29.11-8.786z"
          />
          <path
            fill="#E94235"
            d="M59.984 0L0 59.984l30.876 8.765l29.108-8.765l8.626-27.545z"
          />
          <path fill="#2684FC" d="M.001 150.679h59.983V59.983H.001z" />
          <path
            fill="#00AC47"
            d="M241.659 25.398L203.34 56.834v98.46l38.477 31.558c5.76 4.512 14.186.4 14.186-6.922V32.18c0-7.403-8.627-11.495-14.345-6.781"
          />
          <path
            fill="#00AC47"
            d="M144.822 105.322v45.338H59.984v59.984h125.804c9.69 0 17.553-7.866 17.553-17.554v-37.796z"
          />
          <path
            fill="#FFBA00"
            d="M185.788 0H59.984v59.984h84.838v45.338l58.52-48.49V17.555c0-9.69-7.864-17.554-17.554-17.554"
          />
        </svg>
        Google Meet -
        {` ${moment(start).format('DD/MM/YYYY')} das ${moment(start).format(
          'HH:mm',
        )} às ${moment(end).format('HH:mm')} `}
      </a>
      <div className="modal-action mt-12">
        <button className="btn btn-outline   " onClick={() => closeModal()}>
          Fechar
        </button>
      </div>
    </>
  );
}

export default VacationModalBody;
