import { useForm } from 'react-hook-form';
import { Phone } from 'react-telephone';

const PhoneInput = ({ setPhoneNumber }) => {
  const { register, watch } = useForm();
  setPhoneNumber(watch('phoneNumber'))

  return (
    <>
    <label className="block font-work font-medium leading-5 text-[16px] text-black mt-2">
        <span className='label-text text-base-content'>
          Telefone
        </span>
      </label>
            <Phone className="relative rounded shadow-sm bg-grey-800 -space-y-px" {...register('phoneNumber')} >
            <label className="form-control w-full max-w-xs">

      <Phone.Country className="select select-bordered max-w-xs mb-1"  />
              </label>
      <Phone.Number
        placeholder="6 12 34 56 78"
        className="input input-bordered w-full"
      />
    </Phone>
    </>
  );
}

export default PhoneInput;
