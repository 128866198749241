import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  avatar: '',
};

const avatarSlice = createSlice({
  name: 'avatar',
  initialState,
  reducers: {
    addAvatar: (state, { payload }) => {
      const { data: avatar } = payload;
      state.avatar = avatar ;
    },
  },
});

export const { addAvatar } = avatarSlice.actions;

export default avatarSlice.reducer;
