import { useState } from 'react';
import TitleCardDefault from '../../../../../shared/components/Cards/TitleCardDefault';
import useGallery from '../../../hooks/UseGallery';

export default function GalleryVideo() {
  const { fileList } = useGallery();
  const [activeVid, setActiveVid] = useState({
    id: '1Em3sMvr9AcMC1YovtlgKd275vl4zAES-',
    name: 'Conheça seu passado',
    url: 'https://drive.google.com/file/d/1eskmULEaebw6eSe-_SzkQPEAUaULQV7v/preview',
    thumbnail:
      'https://drive.google.com/thumbnail?authuser=0&id=1eskmULEaebw6eSe-_SzkQPEAUaULQV7v',
    description: 'Conheça seu passado.',
  });
  return (
    <div className="card w-full p-6 grid col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault
        title="Galeria de Vídeos"
        subtitle="Galeria de Conteúdos"
        description="Conheça alguns vídeps que podem ajudar."
      />
      <div className="col-span-8 rounded-lg shadow-lg h-[400px]">
        <iframe
          src={activeVid.url}
          className="aspect-video w-full rounded-t-2xl"
          title="teste"
        />
        <div className="ml-5 text-left text-xl text-slate-600">
          {activeVid.name}
          <p className="text-lg pt-2">{activeVid.description}</p>
        </div>
      </div>
      <div className="col-span-4">
        <div className="relative shadow-lg p-5 flex flex-col border-slate-200 rounded-lg h-[800px]">
          <div className="bg-[#3bbdcf] text-white absolute top-0 left-0 rounded-t-2xl w-full">
            <h3 className="text-lg p-3 ml-3 font-semibold">Lista de vídeos</h3>
          </div>
          <div
            className="overflow-y-auto relative top-12"
            style={{ height: 'min(650px)' }}
          >
            {fileList.map((e, index) => {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  role="button"
                  tabIndex={index}
                  title="Clique para Assitir"
                  className="hover:bg-gray-300 p-2
                                       border-2 rounded-xl
                                       shadow-xl shadow-gray-300"
                  onClick={() => {
                    setActiveVid({
                      id: e.id,
                      name: e.name,
                      url: `https://drive.google.com/file/d/${e.id}/preview`,
                      thumbnail: `https://drive.google.com/thumbnail?authuser=0&id=${e.id}`,
                      description: e.description,
                    });
                  }}
                >
                  <div className="flex items-center">
                    <img
                      className="float-left align-middle mr-5 w-[168px]"
                      src={`https://drive.google.com/thumbnail?authuser=0&id=${e.id}`}
                      alt="thumbnail"
                    />
                    <div className="table-cell align-middle">
                      <p className="font-semibold text-base">{e.name}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
