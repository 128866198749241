import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { supabase } from '../../data/supabase';
import InputText from '../components/Input/InputText';
import { useAuth } from '../context/useAuth';

const Login = () => {
  const INITIAL_LOGIN_OBJ = {
    password: '',
    emailId: '',
  };

  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState('');
  const [loginObj, setLoginObj] = useState(INITIAL_LOGIN_OBJ);
  const { signInWithGoogle, typeUser, user } = useAuth();

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: loginObj.emailId,
        password: loginObj.password,
      });

      if (error) {
        return toast.error(
          error.message === 'Invalid login credentials'
            ? 'Email ou senha incorretos'
            : error.message,
        );
      }

      toast.success('Usuario logado com sucesso');
      history.push(`/inicio/${typeUser}`);
    } catch (error) {
      console.log(error);
      toast.error('algo deu errado, tente novamente');
    }
  };

  const handleFunil = () => {
    axios.post(
      'https://webhook.sellflux.app/webhook/custom/lead/9f4a44a16dec1a082e77acf25f2a5992',
      {
        params: {
          name: '',
          phone: user?.phone,
          email: user?.email,
        },
      },
    );
  };

  const updateFormValue = ({ updateType, value }) => {
    setErrorMessage('');
    setLoginObj({ ...loginObj, [updateType]: value });
  };

  return (
    <div className="max-w-full bg-white flex items-center laptop:pt-12 laptop:pb-12 gap-64 justify-center">
      <div className="laptop:col-span-6 col-span-12 laptop:block hidden">
        <img src="/image.svg" alt="hero" />
      </div>
      <div className="laptop:col-span-6 col-span-12 h-full laptop:p-12 laptop:bg-[#F4F7FB] laptop:rounded-3xl overflow-hidden">
        <div className="flex justify-center flex-col items-center">
          <img
            src="/newlogo.png"
            alt="logo"
            width={120}
            height={120}
            className="justify-center items-center text-center "
          />
          <h2 className="font-work font-bold text-[26px] leading-[54px] -tracking-2 text-center text-black">
            Entrar
          </h2>
        </div>
        <div className="flex items-center justify-center">
          <a
            href={
              history.location.pathname.split('/')[2] === 'pro'
                ? '/cadastrar/pro'
                : '/cadastrar/cli'
            }
          >
            <button className="flex items-center justify-center font-work font-medium leading-4 text-[12px] w-[152px] h-[33px] px-6 mr-6 rounded-full text-sm laptop:text-base text-black p-[24px] bg-[#81818159]">
              Se Cadastrar
            </button>
          </a>
          <a
            href={
              history.location.pathname.split('/')[2] === 'pro'
                ? '/login/pro'
                : '/login/cli'
            }
          >
            <button className="flex items-center justify-center font-work font-medium leading-4 text-[12px] w-[152px] h-[33px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0]">
              Entrar
            </button>
          </a>
        </div>

        <form className="mx-auto mt-6 max-w-[450px]" onSubmit={submitForm}>
          <div>
            <InputText
              type="emailId"
              defaultValue={loginObj.emailId}
              updateType="emailId"
              containerStyle="mt-4"
              labelTitle="Email"
              updateFormValue={updateFormValue}
            />

            <InputText
              defaultValue={loginObj.password}
              type="password"
              updateType="password"
              containerStyle="mt-4"
              labelTitle="Senha"
              updateFormValue={updateFormValue}
            />
          </div>

          <div className="mt-6 flex items-center justify-center">
            <button
              onClick={() => handleFunil()}
              className="flex items-center justify-center font-work font-bold leading-4 text-[16px] w-[249px] h-[52px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0] border-[#3BBED0] border-2"
            >
              Entrar
            </button>
          </div>
        </form>

        {/* <div className="flex items-center justify-between mt-4">
          <span className="w-1/5 border-b border-[#818181]  lg:w-1/3" />

          <a
            href="#"
            className="font-work font-medium leading-5 text-[16px] text-[#818181] text-center"
          >
            ou se preferir
          </a>

          <span className="w-1/5 border-b border-[#818181]  lg:w-1/3" />
        </div>

        <div className="flex items-center justify-center mt-6 -mx-2">
          <button
            type="button"
            onClick={signInWithGoogle}
            className="text-white w-full  bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center  mr-2 mb-2"
          >
            <svg
              className="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
            >
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
              />
            </svg>
            Faça login com Google
          </button>
        </div> */}

        <p className="font-work font-medium leading-5 text-[16px] text-[#3BBED0] mt-8 text-center">
          {' '}
          <a
            href="#"
            onClick={() => history.push(`/senha/${typeUser}`)}
            className="underline decoration-1"
          >
            Esqueceu seu login ou senha?
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
