import moment from 'moment';
import { useState, useEffect } from 'react';
import axios from 'axios';
import TitleCardDefault from '../../../../../shared/components/Cards/TitleCardDefault';
import usePayment from '../../../hooks/UsePayment';
import { useAuth } from '../../../../../shared/context/useAuth';

function Billing() {
  const [payments, setPayments] = useState([]);
  const { amount } = usePayment();
  const { user, patient } = useAuth();
  const getPayments = () => {
    axios
      .get('https://app-back-embrace.vercel.app/api/payments', {
        params: {
          email: user.email,
        },
      })
      .then((response) => setPayments(response.data.data))
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPayments();
  }, []);

  const getPaymentStatus = (status) => {
    if (status === 'paid')
      return <div className="badge badge-success">Pago</div>;
    if (status === 'unpaid')
      return <div className="badge badge-primary">Não pago</div>;
    return <div className="badge badge-ghost">{status}</div>;
  };

  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault
        title="Histórico de Pagamentos"
        subtitle="Pagamentos"
        description="Todos seus pagamentos no mesmo lugar"
      />

      <div className="stats bg-base-100 shadow mb-4 mobile:col-span-12 mobile:w-full">
        <div className="stat">
          <div className="stat-title">Créditos Ativos</div>
          <div className="stat-value">$ {amount || 0}</div>
        </div>
      </div>
      {/* Invoice list in table format loaded constant */}
      <div className="overflow-x-auto w-full">
        {payments?.length > 0 ? (
          <table className="table w-full overflow-hidden h-full">
            <thead>
              <tr>
                <th>Data</th>
                <th>Cliente</th>
                <th>Tipo de Sessão</th>
                <th>Valor</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {payments?.map((item, key) => (
                <tr key={key}>
                  <td> {moment.unix(item.created).format('DD/MM/YYYY')}</td>
                  <td>{item.customer.email}</td>
                  <td>{item.line_items.data[0].description}</td>
                  <td>${item.amount_total / 100}</td>
                  <td>{getPaymentStatus(item.payment_status)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h3 className="mt-12 text-xl font-thin">
            Nenhuma transação foi realizada
          </h3>
        )}
      </div>
    </div>
  );
}

export default Billing;
