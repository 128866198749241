import brLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAppointment from '../../../../shared/appointment/hooks/UseAppointment';
import TitleCardSchedule from '../../../../shared/components/Cards/TitleCardSchedule';
import { openModal } from '../../../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../../../utils/globalConstantUtil';
import useSchedule from '../../hooks/UseSchedule';

function Appointment() {
  const dispatch = useDispatch();
  const { schedule, updateSchedule } = useSchedule();
  const { appointments } = useAppointment();

  const daysOfWeek = {
    0: 'Domingo',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado',
  };

  const daysOfYearByDayOfWeek = (dayOfWeek) => {
    const daysOfYear = moment().startOf('year').day(dayOfWeek);
    if (daysOfYear.date() > 7) daysOfYear.add(7, 'd');
    const year = daysOfYear.year();
    const arrayDaysOfYear = [];
    while (year === daysOfYear.year()) {
      arrayDaysOfYear.push(daysOfYear.format('YYYY-MM-DD'));
      daysOfYear.add(7, 'd');
    }
    return arrayDaysOfYear;
  };

  const [businessHours, setBusinessHours] = useState([]);
  const [eventsFullCalendar, setEventsFullCalendar] = useState([]);
  const [scheduleAvailability, setScheduleAvailability] = useState([
    {
      enabled: false,
      endTime: '18:00',
      dayOfWeek: 0,
      startTime: '09:00',
      endIntervalTime: '13:00',
      startIntevalTime: '12:00',
    },
    {
      enabled: true,
      endTime: '18:00',
      dayOfWeek: 1,
      startTime: '09:00',
      endIntervalTime: '13:00',
      startIntevalTime: '12:00',
    },
    {
      enabled: true,
      endTime: '18:00',
      dayOfWeek: 2,
      startTime: '09:00',
      endIntervalTime: '13:00',
      startIntevalTime: '12:00',
    },
    {
      enabled: true,
      endTime: '18:00',
      dayOfWeek: 3,
      startTime: '09:00',
      endIntervalTime: '13:00',
      startIntevalTime: '12:00',
    },
    {
      enabled: true,
      endTime: '18:00',
      dayOfWeek: 4,
      startTime: '09:00',
      endIntervalTime: '13:00',
      startIntevalTime: '12:00',
    },
    {
      enabled: true,
      endTime: '18:00',
      dayOfWeek: 5,
      startTime: '09:00',
      endIntervalTime: '13:00',
      startIntevalTime: '12:00',
    },
    {
      enabled: false,
      endTime: '18:00',
      dayOfWeek: 6,
      startTime: '09:00',
      endIntervalTime: '13:00',
      startIntevalTime: '12:00',
    },
  ]);

  const handleSubmit = () => {
    const payloadScheduleAvailability = scheduleAvailability.map((sa) => {
      return daysOfYearByDayOfWeek(sa.dayOfWeek).map((dates) => {
        const all = {
          ...sa,
          dateOfWeek: moment(dates).format('DD/MM/YYYY'),
        };

        return all;
      });
    });
    updateSchedule(scheduleAvailability, payloadScheduleAvailability);
  };

  // const state = {
  //   modal: false,
  //   calendarWeekends: true,
  //   event: [],
  // };

  const [modal, setModal] = useState({});

  const toggle = () => {
    setModal({ modal: !modal });
  };

  const handleEventClick = ({ event }) => {
    dispatch(
      openModal({
        title: 'Detalhe Consulta',
        bodyType: MODAL_BODY_TYPES.APPOINTMENT_DETAILS,
        extraObject: {
          title: event.title,
          patient: event.extendedProps.patient,
          status: event.extendedProps.status,
          meetLink: event.extendedProps.meetLink,
          start: event.start,
          end: event.end,
        },
      }),
    );
    toggle();
    setModal({ event });
  };

  const handleBusinessHours = (data) => {
    const arrayBusinessHours = [];
    const arrayIntervals = [];
    data.forEach((item, index) => {
      if (item.enabled) {
        const businesHours = {
          daysOfWeek: [index],
          startTime: item.startTime,
          endTime: item.endTime,
        };
        arrayBusinessHours.push(businesHours);
        const hoursIntervals = {
          groupId: 'Intervalo',
          title: 'Intervalo',
          daysOfWeek: [index],
          startTime: item.startIntevalTime,
          endTime: item.endIntervalTime,
          display: 'background',
          backgroundColor: '#ededed',
          borderColor: '#ededed',
          textColor: '#000000',
          editable: false,
        };
        arrayIntervals.push(hoursIntervals);
      }
    });
    setBusinessHours(arrayBusinessHours);
    setEventsFullCalendar(arrayIntervals);
  };

  useEffect(() => {
    if (schedule && Object.keys(schedule).length) {
      setScheduleAvailability(schedule.schedule_availability);
      handleBusinessHours(schedule.schedule_availability);
    }
  }, [schedule]);

  const handleStatus = (status) => {
    const sts = { value: '', color: '' };
    switch (status) {
      case 'confirmed':
        sts.value = 'Confirmado';
        sts.color = '#3788d8';
        break;
      case 'pending':
        sts.value = 'Pendente';
        sts.color = '#ffc107';
        break;
      case 'canceled':
        sts.value = 'Cancelado';
        sts.color = '#dc3545';
        break;
      default:
        break;
    }
    return sts;
  };

  useEffect(() => {
    const event = appointments.map((item) => {
      return {
        id: 'Consulta',
        title: `Consulta ${item.patient.name}`,
        patient: item.patient.name,
        status: item.status,
        meetLink: item.meetLink,
        backgroundColor: handleStatus(item.status).color,
        borderColor: handleStatus(item.status).color,
        start: moment.utc(item.start).format(),
        end: moment.utc(item.end).format(),
      };
    });
    setEventsFullCalendar([...eventsFullCalendar, ...event]);
  }, [appointments]);

  const handleFormChange = (index, event) => {
    const data = [...scheduleAvailability];
    data[index][event.target.name] = event.target.value;
    setScheduleAvailability(data);
    handleBusinessHours(data);
  };

  const handleCheckboxChange = (index, event) => {
    const data = [...scheduleAvailability];
    data[index][event.target.name] = event.target.checked;
    setScheduleAvailability(data);
    handleBusinessHours(data);
  };

  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardSchedule title="Agendamentos" />
      <div className="col-span-6 relative">
        <form
          onSubmit={() => handleSubmit()}
          className="flex flex-col rounded-2xl border border-[#e5e7eb] p-6"
        >
          <div className="bg-[#3bbdcf] text-white absolute top-0 left-0 rounded-t-2xl w-full">
            <div className="text-base font-semibold p-4">
              Defina o intervalo de horário que você está disponível em cada dia
              da semana.
            </div>
          </div>

          <div className="divider mt-2" />
          {scheduleAvailability?.map((scheduleTime, index) => {
            return (
              <div
                className={`flex items-center justify-between p-3 ${
                  index > 0 ? 'border-t' : ''
                }`}
                key={index}
              >
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="enabled"
                    className="checkbox"
                    checked={scheduleTime.enabled}
                    onChange={(event) => handleCheckboxChange(index, event)}
                  />
                  <span className="label-text">
                    {daysOfWeek[scheduleTime.dayOfWeek]}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex items-center box-border border-1">
                    <input
                      className={`rounded-md p-2 bg-[#f8f8f8] ${
                        !scheduleTime.enabled ? 'cursor-not-allowed' : ''
                      }`}
                      type="time"
                      name="startTime"
                      disabled={!scheduleTime.enabled}
                      value={scheduleTime.startTime}
                      onChange={(event) => handleFormChange(index, event)}
                    />
                  </div>
                  <div className="flex items-centerbox-border border-1">
                    <input
                      className={`rounded-md p-2 bg-[#f8f8f8] ${
                        !scheduleTime.enabled ? 'cursor-not-allowed' : ''
                      }`}
                      type="time"
                      name="startIntevalTime"
                      disabled={!scheduleTime.enabled}
                      value={scheduleTime.startIntevalTime}
                      onChange={(event) => handleFormChange(index, event)}
                    />
                  </div>
                  <div className="flex items-center box-border border-1">
                    <input
                      className={`rounded-md p-2 bg-[#f8f8f8] ${
                        !scheduleTime.enabled ? 'cursor-not-allowed' : ''
                      }`}
                      type="time"
                      name="endIntervalTime"
                      disabled={!scheduleTime.enabled}
                      value={scheduleTime.endIntervalTime}
                      onChange={(event) => handleFormChange(index, event)}
                    />
                  </div>
                  <div className="flex items-center box-border border-1">
                    <input
                      className={`rounded-md p-2 bg-[#f8f8f8] ${
                        !scheduleTime.enabled ? 'cursor-not-allowed' : ''
                      }`}
                      type="time"
                      name="endTime"
                      disabled={!scheduleTime.enabled}
                      value={scheduleTime.endTime}
                      onChange={(event) => handleFormChange(index, event)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mt-3">
            <button
              type="button"
              onClick={() => handleSubmit()}
              className="btn btn-primary float-right mr-3"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
      <div className="col-span-6">
        <div className="rounded-2xl border border-[#e5e7eb] p-6 relative">
          <div className="bg-[#3bbdcf] text-white absolute top-0 left-0 rounded-t-2xl w-full">
            <div className="text-base font-semibold p-4">Agenda</div>
          </div>
          <div className="divider mt-2" />
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            businessHours={businessHours}
            locale={brLocale}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            displayEventEnd={true}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: false,
            }}
            events={eventsFullCalendar}
            // eslint-disable-next-line react/jsx-no-bind
            eventClick={handleEventClick}
          />
          <div className="text-center inline-flex items-center mt-2">
            <div className="w-5 h-5 rounded-full bg-[#3788d8] me-2" />
            Confirmado
            <div className="w-5 h-5 rounded-full bg-[#ffc107] me-2 ml-2" />
            Pendente
            <div className="w-5 h-5 rounded-full bg-[#dc3545] me-2 ml-2" />
            Cancelado
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appointment;
