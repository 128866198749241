import CheckCircleIcon from '@heroicons/react/24/solid/CheckCircleIcon';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../data/supabase';
import InputText from '../components/Input/InputText';
import ErrorText from '../components/Typography/ErrorText';
import { useAuth } from '../context/useAuth';

function ResetPassword() {
  const INITIAL_USER_OBJ = {
    password: '',
  };
  const { typeUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [userObj, setUserObj] = useState(INITIAL_USER_OBJ);

  const submitForm = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    await supabase.auth.updateUser({ password: userObj.password })



    if (userObj.password.trim() === '')
      return setErrorMessage('Email é obrigatório');

    setLoading(true);
    // Call API to send password reset link
    setLoading(false);
    setLinkSent(true);
  };

  const updateFormValue = ({ updateType, value }) => {
    setErrorMessage('');
    setUserObj({ ...userObj, [updateType]: value });
  };

  return (
    <div className="min-h-screen bg-base-200 p-[10%] flex items-center">
      <div className="card mx-auto w-full max-w-5xl shadow-xl">
        <div className="grid  md:grid-cols-2 grid-cols-1  bg-base-100 rounded-xl">

          <div className="py-24 px-10">
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Redefina sua senha
            </h2>

            {linkSent && (
              <>
                <div className="text-center mt-8">
                  <CheckCircleIcon className="inline-block w-32 text-success" />
                </div>
                <p className="my-4 text-xl font-bold text-center">Senha alterada com sucesso!</p>

                <div className="text-center mt-4">
                  <Link to={`/login/${typeUser}`}>
                    <button onClick={() => localStorage.clear()} className="flex items-center justify-center font-work font-medium leading-4 text-[18px] w-[152px] h-[33px] px-6 rounded-full text-sm desktop:text-base text-white p-[24px] bg-[#3BBED0] w-full">
                      Faça login
                    </button>
                  </Link>
                </div>
              </>
            )}

            {!linkSent && (
              <form onSubmit={(e) => submitForm(e)}>
                  <div className="mb-4">
                    <InputText
                      type="password"
                      defaultValue={userObj.password}
                      updateType="password"
                      containerStyle="mt-4"
                      labelTitle="Nova Senha"
                      updateFormValue={updateFormValue}
                    />



                  </div>

                  <ErrorText styleClass="mt-12">{errorMessage}</ErrorText>
                  <button
                    type="submit"
                    className={`flex items-center justify-center font-work font-bold leading-4 text-[16px] w-[249px] h-[52px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0] w-full border-[#3BBED0] border-2${
                      loading ? ' loading' : ''
                    }`}
                  >
                    Redefinir
                  </button>


                  <div className="text-center mt-4">
                    Nao tem uma conta?{' '}
                    <Link to={`/cadastrar/${typeUser}`}>
                      <button className="  inline-block  hover:text-primary hover:underline hover:cursor-pointer transition duration-200">
                        Cadastre-se
                      </button>
                    </Link>
                  </div>
                </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
