import { useState } from 'react';
import { Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import { supabase } from '../../data/supabase';
import { Cadastro, Login, LoginAdmin, Register } from '../pages';
import ForgotPasswordPage from '../pages/ForgotPassword';
import { LoginDuo } from '../pages/LoginDuo';

export const ResertPassWord = () => {
  const [password, setPassword] = useState();

  const handleResetPassword = (e) => {
    e.preventDefault();
    setPassword(e.target[0].value);
    supabase.auth.onAuthStateChange(async () => {
      const { data, error } = await supabase.auth.updateUser({
        password,
      });
      if (data) toast.success('Senha atualizada com sucesso!');
      if (error) toast.error('Houve algum erro, tente novamente.');
    });
  };

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:text-left">
          <h1 className="text-5xl font-bold">Mude sua senha</h1>
          <p className="py-6">Altere sua senha e faça login novamente.</p>
        </div>
        <div className="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
          <form className="card-body" onSubmit={handleResetPassword}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Nossa senha</span>
              </label>
              <input
                type="password"
                placeholder="Nova Senha"
                className="input input-bordered"
                required
              />
            </div>
            <div className="form-control mt-6">
              <button
                type="submit"
                className="flex items-center justify-center font-work font-medium leading-4 text-[12px] w-[152px] h-[33px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0]"
              >
                Alterar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const PublicRoutes = () => (
  <>
    <Route path="/login" exact component={LoginDuo} />
    <Route path="/senha/cli" exact component={ForgotPasswordPage} />
    <Route path="/senha/pro" exact component={ForgotPasswordPage} />
    <Route path="/login/admin" exact component={LoginAdmin} />
    <Route path="/login/pro" exact component={Login} />
    <Route path="/cadastro" exact component={Cadastro} />
    <Route path="/cadastrar/pro" component={Register} />
    <Route path="/login/cli" exact component={Login} />
    <Route path="/cadastrar/cli" component={Register} />
    {/* <Route path="*" component={NotFound} /> */}
  </>
);

export default PublicRoutes;
