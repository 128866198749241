import ForgotPassword from '../../shared/user/ForgotPassword';

function ForgotPasswordPage() {
  return (
    <div className="">
      <ForgotPassword />
    </div>
  );
}

export default ForgotPasswordPage;
