import React from 'react';
import Checkout from './StripeCheckout/Checkout';
import TitleCardPayment from '../../../../shared/components/Cards/TitleCardPayment';

function Payment() {
  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardPayment title="Créditos" />
      <Checkout />
    </div>
  );
}

export default Payment;
