import React from 'react';

export default function Cancel() {
  return (
    <>
      <h1>Cancelado</h1>
      <h2>Seu pagamento foi cancelado.</h2>
    </>
  );
}
