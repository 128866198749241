/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/function-component-definition */
import React from 'react';

const Tests = () => (
  <div className="col-span-12 no-scrollbar">
    <iframe className="col-span-12 w-full min-h-[80vh]" src="https://testes.embraceinternacional.com" />
  </div>
);

export default Tests;
