import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { supabase } from '../../data/supabase';
import InputText from '../components/Input/InputText';
import { useAuth } from '../context/useAuth';

const LoginAdmin = () => {
  const INITIAL_LOGIN_OBJ = {
    password: '',
    emailId: '',
  };

  const history = useHistory();

  const [loading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginObj, setLoginObj] = useState(INITIAL_LOGIN_OBJ);
  const { signInWithGoogle, typeUser } = useAuth();

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: loginObj.emailId,
        password: loginObj.password,
      });

      if (error) {
        return toast.error(error.message === "Invalid login credentials" ? 'Email ou senha incorretos' : error.message)
      };

      toast.success('Usuario logado com sucesso');
      history.push('/inicio/admin');
    } catch (error) {
      console.log(error);
      toast.error('algo deu errado, tente novamente');
    }
  };

  const updateFormValue = ({ updateType, value }) => {
    setErrorMessage('');
    setLoginObj({ ...loginObj, [updateType]: value });
  };

  return (
    <div className="min-h-screen desktop:p-12 bg-white flex items-center">
      <div className="desktop:col-span-6 col-span-12 desktop:block hidden">
        <div className="relative w-[395] h-[470]">
          <img
            src="/image.svg"
            className="relative col-start-7 col-end-12"
            alt="hero"
          />
          <div className="absolute w-56 right-2 desktop:left-28 desktop:top-0 bg-[#FFF] shadow-lg rounded p-4">
            <div className="flex float-right m-0 text-[#818181] before:content-['\2807']"></div>
            <p className="font-work text-[14px] font-medium leading-5">
              Clientes Ativos
            </p>
            <span className="font-work text-[16px] font-bold leading-5">
              2,497
            </span>
            <div className="flex float-right px-2 py-1  mt-4 w-12 font-work text-[10px] font-medium text-[#87C87F] bg-[#ECF0EF]">
              <span className="self-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[10px] h-[10px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                  />
                </svg>
              </span>
              <span className="">20%</span>
            </div>
          </div>
          <div className="absolute w-32 h-28 flex justify-center items-center flex-col desktop:right-40 desktop:bottom-24 bg-[#FFFFFF] rounded-2xl p-4">
            <img
              src="InfoGraphic/maki_doctor.svg"
              width={32}
              height={32}
              alt="maki doctor"
            />
            <p className="font-Work text-[10px] text-center font-semibold leading-3">
              +5000 Consultas Realizadas
            </p>
          </div>
        </div>
      </div>
      <div className="desktop:col-span-6 col-span-12 desktop:p-12 mx-auto desktop:bg-[#F4F7FB] desktop:rounded-3xl desktop:h-full h-[100vh] overflow-hidden">
        <h2 className="font-work font-bold text-[46px] desktop:text-[46px] leading-[54px] -tracking-2 text-center text-black my-6">
          Entrar
        </h2>
        <div className="flex items-center justify-center">

          <a href={history.location.pathname.split('/')[2] === "pro" ?  "/login/pro" : "/login/cli"}>

          <button className="flex items-center justify-center font-work font-medium leading-4 text-[12px] w-[152px] h-[33px] px-6 rounded-full text-sm desktop:text-base text-white p-[24px] bg-[#3BBED0]">
            Entrar
          </button>
          </a>

        </div>

        <form className="mx-auto mt-6 max-w-[450px]" onSubmit={submitForm}>
      <div>
        <InputText
          type="emailId"
          defaultValue={loginObj.emailId}
          updateType="emailId"
          containerStyle="mt-4"
          labelTitle="Email"
          updateFormValue={updateFormValue}
        />

        <InputText
          defaultValue={loginObj.password}
          type="password"
          updateType="password"
          containerStyle="mt-4"
          labelTitle="Senha"
          updateFormValue={updateFormValue}
        />
      </div>

      <div className="mt-6 flex items-center justify-center">
        <button className="flex items-center justify-center font-work font-bold leading-4 text-[16px] w-[249px] h-[52px] px-6 rounded-full text-sm desktop:text-base text-white p-[24px] bg-[#3BBED0] border-[#3BBED0] border-2">
          Entrar
        </button>
      </div>
    </form>

        {/* <div className="flex items-center justify-between mt-4">
          <span className="w-1/5 border-b border-[#818181]  lg:w-1/3" />

          <a
            href="#"
            className="font-work font-medium leading-5 text-[16px] text-[#818181] text-center"
          >
            ou se preferir
          </a>

          <span className="w-1/5 border-b border-[#818181]  lg:w-1/3" />
        </div>

        <div className="flex items-center justify-center mt-6 -mx-2">
          <button
            type="button"
            onClick={signInWithGoogle}
            className="text-white w-full  bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center  mr-2 mb-2"
          >
            <svg
              className="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
            >
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
              />
            </svg>
            Faça login com Google
          </button>
        </div> */}

        <p className="font-work font-medium leading-5 text-[16px] text-[#3BBED0] mt-8 text-center">
          {' '}
          <a
            href="#"
            onClick={() => history.push(`/senha/${typeUser}`)}
            className="underline decoration-1"
          >
            Esqueceu seu login ou senha?
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginAdmin;
