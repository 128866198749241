import CheckCircleIcon from '@heroicons/react/24/solid/CheckCircleIcon';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../data/supabase';
import InputText from '../../shared/components/Input/InputText';
import ErrorText from '../../shared/components/Typography/ErrorText';
import { useAuth } from '../../shared/context/useAuth';

function ForgotPassword() {
  const INITIAL_USER_OBJ = {
    emailId: '',
  };

  const { typeUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [userObj, setUserObj] = useState(INITIAL_USER_OBJ);

  const submitForm = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    await supabase.auth.resetPasswordForEmail(userObj.emailId, {
      redirectTo: `http://app.embraceinternacional.com/mudar-senha/${typeUser}`,
      // redirectTo: `http://localhost:3000/mudar-senha/${typeUser}`,
    });

    if (userObj.emailId.trim() === '')
      return setErrorMessage('Digite um email para continuar!');

    setLoading(true);
    // Call API to send password reset link
    setLoading(false);
    setLinkSent(true);
  };

  const updateFormValue = ({ updateType, value }) => {
    setErrorMessage('');
    setUserObj({ ...userObj, [updateType]: value });
  };

  return (
    <div className="min-h-screen bg-base-200 flex items-center p-[10%]">
      <div className="card mx-auto w-full max-w-5xl   shadow-xl">
        <div className="grid  md:grid-cols-2 grid-cols-1   bg-base-100 rounded-xl">
          <div className="py-24 px-10">
            <h2 className="text-2xl font-semibold mb-2 text-center">
              Renove sua senha
            </h2>

            {linkSent && (
              <>
                <div className="text-center mt-8">
                  <CheckCircleIcon className="inline-block w-32 text-success" />
                </div>
                <p className="my-4 text-xl font-bold text-center">
                  Link enviado
                </p>
                <p className="mt-4 mb-8 font-semibold text-center">
                  Verifique seu email
                </p>
                <div className="text-center mt-4">
                  <Link to={`/login/${typeUser}`}>
                    <button className="flex items-center justify-center font-work font-bold leading-4 text-[16px] w-[249px] h-[52px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0] w-full border-[#3BBED0] border-2 ">
                      Login
                    </button>
                  </Link>
                </div>
              </>
            )}

            {!linkSent && (
              <>
                <p className="my-8 font-semibold text-center">
                  Enviaremos uma nova senha por email
                </p>
                <form onSubmit={(e) => submitForm(e)}>
                  <div className="mb-4">
                    <InputText
                      type="emailId"
                      defaultValue={userObj.emailId}
                      updateType="emailId"
                      containerStyle="mt-4"
                      labelTitle="Email"
                      updateFormValue={updateFormValue}
                    />
                  </div>

                  <ErrorText styleClass="mt-12">{errorMessage}</ErrorText>
                  <button
                    type="submit"
                    className={`flex items-center justify-center font-work font-bold leading-4 text-[16px] w-[249px] h-[52px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0] w-full border-[#3BBED0] border-2${
                      loading ? ' loading' : ''
                    }`}
                  >
                    Enviar
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
