import { useState } from 'react';
import useProfile from '../../../modules/specialist/hooks/UseProfile';

function VacationModalBody({ extraObject, closeModal }) {
  const { message } = extraObject;

  const [subject, setSubject] = useState('vacation');
  const [specialistLogs, setSpecialistLogs] = useState([]);
  const { disableProfile, specialist } = useProfile();

  const handleCheckboxChange = (event) => {
    setSubject(event.target.value);
    setSpecialistLogs({});
  };

  const handleSubmitConfirm = () => {
    if (Object.values(specialistLogs).every((item) => item != null)) {
      specialistLogs.subject = subject;
      specialistLogs.specialist = specialist;
      disableProfile(specialistLogs);
      closeModal();
    } else {
      alert('preencha tudo');
    }
  };

  const handleFormChange = (event) => {
    const data = specialistLogs;
    data[event.target.name] = event.target.value;
    setSpecialistLogs(data);
  };

  return (
    <>
      <h1 className=" text-xl">{message}</h1>
      <form>
        <div
          className="form-control"
          onChange={(event) => handleCheckboxChange(event)}
        >
          <label className="label cursor-pointer">
            <span className="label-text">Férias</span>
            <input
              type="radio"
              name="subject"
              value="vacation"
              checked={subject === 'vacation'}
              className="radio checked:bg-blue-500"
            />
          </label>
          <label className="label cursor-pointer">
            <span className="label-text">Outros motivos</span>
            <input
              type="radio"
              name="subject"
              value="others"
              checked={subject === 'others'}
              className="radio checked:bg-blue-500"
            />
          </label>
        </div>
        <div
          className={`form-control ${subject === 'others' ? 'show' : 'hidden'}`}
        >
          <textarea
            name="about"
            className="textarea textarea-bordered"
            placeholder="Descreva o motivo"
            onChange={(event) => handleFormChange(event)}
          />
        </div>
        <div
          className={`form-control ${subject === 'vacation' ? 'show' : 'hidden'}`}
        >
          <label className="label">
            <div className="label-text">Periodo das férias:</div>
          </label>
          <div className="flex items-center">
            <input
              name="start"
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Data início"
              onChange={(event) => handleFormChange(event)}
            />
            <span className="mx-4 text-gray-500">à</span>
            <input
              name="end"
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Data fim"
              onChange={(event) => handleFormChange(event)}
            />
          </div>
        </div>

        <div className="modal-action mt-12">
          <button className="btn btn-outline   " onClick={() => closeModal()}>
            Cancelar
          </button>

          <button
            className="btn btn-primary w-36"
            onClick={() => handleSubmitConfirm()}
          >
            Confirmar
          </button>
        </div>
      </form>
    </>
  );
}

export default VacationModalBody;
