import FunnelIcon from '@heroicons/react/24/outline/FunnelIcon';
import RecordIconAllow from '@heroicons/react/24/outline/VideoCameraIcon';
import RecordIconNotAllow from '@heroicons/react/24/outline/VideoCameraSlashIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { supabase } from '../../../data/supabase';
import TitleCard from '../../../shared/components/Cards/TitleCard';
import SearchBar from '../../../shared/components/Input/SearchBar';
import { useAuth } from '../../../shared/context/useAuth';
import useAppoitmentsPro from '../../../shared/hooks/useAppointment/specialist';

function TopSideButtons({ removeFilter, applyFilter, applySearch }) {
  const [filterParam, setFilterParam] = useState('');
  const [searchText, setSearchText] = useState('');
  const locationFilters = [
    'pending',
    'em andamento',
    'canceled pro',
    'confirmed',
  ];

  const showFiltersAndApply = (params) => {
    applyFilter(params);
    setFilterParam(params);
  };

  const removeAppliedFilter = () => {
    removeFilter();
    setFilterParam('');
    setSearchText('');
  };

  useEffect(() => {
    if (searchText === '') {
      removeAppliedFilter();
    } else {
      applySearch(searchText);
    }
  }, [searchText]);

  return (
    <div className="inline-block float-right">
      <SearchBar
        searchText={searchText}
        styleClass="mr-4"
        setSearchText={setSearchText}
      />
      {filterParam !== '' && (
        <button
          onClick={() => removeAppliedFilter()}
          className="btn btn-xs mr-2 btn-active btn-ghost normal-case"
        >
          {filterParam}
          <XMarkIcon className="w-4 ml-2" />
        </button>
      )}
      <div className="dropdown dropdown-bottom dropdown-end">
        <label tabIndex={0} className="btn btn-sm btn-outline">
          <FunnelIcon className="w-5 mr-2" />
          Filtro
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 text-sm shadow bg-base-100 rounded-box w-52"
        >
          {locationFilters.map((l, k) => (
            <li key={k}>
              <a onClick={() => showFiltersAndApply(l)}>{l}</a>
            </li>
          ))}
          <div className="divider mt-0 mb-0" />
          <li>
            <a onClick={() => removeAppliedFilter()}>Remover Filtro</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function AppointmentsSpecialist() {
  const { specialist } = useAuth();

  const { data: appointmentsPro } = useAppoitmentsPro(specialist[0]?.id);
  const [trans, setTrans] = useState(appointmentsPro);

  useEffect(() => {
    setTrans(appointmentsPro);
  }, [appointmentsPro]);

  const removeFilter = () => {
    setTrans(appointmentsPro);
  };

  const applyFilter = (params) => {
    const filteredTransactions = trans?.filter((t) => {
      return t.status === params;
    });
    setTrans(filteredTransactions);
  };

  const handleCancelMeet = async (link, typeSchedule, id) => {
    await supabase
      .from('appointment')
      .update({ status: 'canceled pro' })
      .eq('meetLink', link)
      .select();

    const { data } = await supabase
      .from('balance')
      .select('amount')
      .eq('patient_id', id)
      .order('id', { ascending: false })
      .limit(1);

    await supabase
      .from('balance')
      .update({
        amount:
          data[0].amount +
          (typeSchedule === 'Terapia individual'
            ? 49
            : typeSchedule === 'Terapia de Casal/Familia'
              ? 69
              : ''),
      })
      .select('amount')
      .eq('patient_id', id)
      .order('id', { ascending: false })
      .limit(1);
    toast.success(
      <span>
        Agendamento Cancelado! <br /> Retorno de crédito realizado para o
        paciente!
      </span>,
      { closeButton: true },
    );

    window.location.href = '/consulta-cancelada/pro';

  };

  // Search according to name
  const applySearch = (value) => {
    const filteredTransactions = trans.filter(
      (t) =>
        t.patient.name.toLowerCase().includes(value.toLowerCase()) ||
        t.patient.name.toLowerCase().includes(value.toLowerCase()),
    );
    setTrans(filteredTransactions);
  };

  // const allowLink = link?.events[0]?.hangoutLink;
  const getDummyStatus = (index) => {
    if (index === 'pending')
      return <div className="badge badge-warning">Pendente</div>;
    if (index === 'running')
      return <div className="badge badge-primary">Em andamento</div>;
    if (index === 'canceled pac')
      return (
        <div className="badge badge-error text-white">Cancelado | Paciente</div>
      );
    if (index === 'canceled pro')
      return (
        <div className="badge badge-error text-white">
          Cancelado | Especialista
        </div>
      );
    if (index === 'confirmed')
      return <div className="badge badge-accent">Confirmado</div>;
    return <div className="badge badge-ghost">Open</div>;
  };

  return (
    <TitleCard
      title="Historico de consultas"
      topMargin="mt-2"
      TopSideButtons={
        <TopSideButtons
          applySearch={applySearch}
          applyFilter={applyFilter}
          removeFilter={removeFilter}
        />
      }
    >
      {/* Team Member list in table format loaded constant */}
      <div className="overflow-x-auto w-full">
        <table className="table w-full overflow-hidden h-full">
          {trans?.length > 0 && (
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Link agenda</th>
                <th>Tipo de Sessão</th>
                <th>Data / Hora</th>
                <th>Status</th>
                <th className="w-[10px]"> </th>
              </tr>
            </thead>
          )}
          <tbody>
            {trans?.length > 0 ? (
              trans?.map((l, k) => (
                <tr key={k}>
                  <td>
                    <div className="flex items-center space-x-3">
                      <div>
                        <div className="font-bold">{l.patient?.name}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {!moment(new Date().toJSON()).isAfter(moment(l.start_history)) ? (
                      <a target="_blank" rel="noreferrer" href={l?.meetLink}>
                        <RecordIconAllow height={22} width={22} />
                      </a>
                    ) : (
                      <div
                        className="tooltip hover:tooltip-open tooltip-bottom"
                        data-tip="Link expirado"
                      >
                        <RecordIconNotAllow height={22} width={22} />
                      </div>
                    )}
                  </td>
                  <td>{l?.category || 'Sem categoria'}</td>
                  <td>
                    {l?.start_history ? (
                      moment(l.start_history).format('D MMM HH:mm')
                    ) : (
                      <p className="text-error">Sem data</p>
                    )}
                  </td>
                  <td>{getDummyStatus(l?.status)}</td>
                  <td className="w-[20px]">
                    {l?.status !== 'canceled pac' || l?.status !== 'canceled pro' &&
                      !moment(new Date().toJSON()).isAfter(moment(l.start_history)) && (
                        <div className="dropdown dropdown-end">
                          <div tabIndex={0} role="button" className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                              />
                            </svg>
                          </div>
                          <ul
                            tabIndex={0}
                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                          >
                            <li>
                              <a
                                onClick={() =>
                                  handleCancelMeet(
                                    l?.meetLink,
                                    l?.category,
                                    l.patient?.id,
                                  )
                                }
                                className="font-bold"
                              >
                                Cancelar
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                  </td>
                </tr>
              ))
            ) : (
              <span className="flex pt-6 text-[#666] font-bold">
                Nada encontrado.
              </span>
            )}
          </tbody>
        </table>
      </div>
    </TitleCard>
  );
}

export default AppointmentsSpecialist;
