import TitleCardDefault from '../../components/Cards/TitleCardDefault';

export default function Legal() {
  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault
        title="Legal"
        subtitle="Informações Legais"
        description="Conheça as políticas e demais informações legais"
      />
      <div className="col-span-6 col-start-4">
        <div className="md:px-32 py-8">
          <div className="shadow overflow-hidden rounded-2xl border-b border-gray-200">
            <table className="min-w-full bg-white">
              <thead className="bg-[#3bbdcf] text-white">
                <tr>
                  <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                    Documento
                  </th>
                  <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                    Atualização
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                <tr className="transition duration-300 ease-in-out hover:bg-gray-100">
                  <td className="text-left py-3 px-4">
                    <a
                      className="hover:text-blue-500"
                      href="/legal/privacypolicy.pdf"
                      target="_blank"
                    >
                      Privacy policy
                    </a>
                  </td>
                  <td className="w-1/3 text-left py-3 px-4">25/05/2024</td>
                </tr>
                <tr className="transition duration-300 ease-in-out hover:bg-gray-100">
                  <td className="text-left py-3 px-4">
                    <a
                      className="hover:text-blue-500"
                      href="/legal/termsofuse.pdf"
                      target="_blank"
                    >
                      Terms of use
                    </a>
                  </td>
                  <td className="w-1/3 text-left py-3 px-4">25/05/2024</td>
                </tr>
                <tr className="transition duration-300 ease-in-out hover:bg-gray-100">
                  <td className="text-left py-3 px-4">
                    <a
                      className="hover:text-blue-500"
                      href="/legal/informedconsent.pdf"
                      target="_blank"
                    >
                      Informed Consent
                    </a>
                  </td>
                  <td className="w-1/3 text-left py-3 px-4">25/05/2024</td>
                </tr>
                <tr className="transition duration-300 ease-in-out hover:bg-gray-100">
                  <td className="text-left py-3 px-4">
                    <a
                      className="hover:text-blue-500"
                      href="/legal/emergencyandcrisis.pdf"
                      target="_blank"
                    >
                      Emergency and Crisis
                    </a>
                  </td>
                  <td className="w-1/3 text-left py-3 px-4">25/05/2024</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
