import React from 'react';
import '../../../../calendar.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useAuth } from '../../../../shared/context/useAuth';
import AppointmentPatient from '../Appointment';
// import usePatientProfile from '../../../../../modules/patients/hooks/UsePatientProfile';

function Dashboard() {
  const { typeUser, patient, specialist } = useAuth();

  return (
    <>
      <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-12 md:col-span-3 lg:col-span-4">
        <h2 className="font-light text-xl">Olá,</h2>
        <h2 className="font-bold text-2xl mb-6">
          {typeUser === 'cli' ? patient[0]?.name : specialist[0]?.name}
        </h2>
        <p>Sua Jornada ao Bem-Estar Começa aqui!</p>
      </div>

      <AppointmentPatient />

      {/** ---------------------- Different stats content 2 ------------------------- */}
    </>
  );
}

export default Dashboard;
