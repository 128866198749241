import { supabase } from "./supabase";

const getSpecialist = async () => {
  const { data: specialist } = await supabase
    .from('specialist')
    .select('*');

  return specialist;
};
export default getSpecialist;
