/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
import clsx from "clsx";
import dayjs from "dayjs";
import styles from "../component.module.css";

const DayView = ({ current, onDateClick, selectedDate }) => {
  const isSelected = current.diff(selectedDate, "day") === 0;
  const isToday = current.isSame(dayjs(), "day");
  return (
    <div
      id={clsx({ "selected-date": isSelected })}
      className={clsx(styles.dateDayItem, {
        [styles.selected]: isSelected,
        [styles.today]: isToday
      })}
      onClick={() => onDateClick(current)}
    >
      <div className={styles.dayLabel}>{current.locale('pt-br').format("ddd")}</div>
      <div className={styles.dateLabel}>{current.locale('pt-br').format("D")}</div>
    </div>
  );
};

export default DayView;
