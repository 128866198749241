import React from 'react';

import Route from './Route';

import { Dashboard, Graphics, Patients, Settings } from '../pages';
import AppointmentsPatientADMIN  from '../../../shared/transactions/Patient/index-copy';
import AppointmentsSpecADMIN  from '../../../shared/transactions/Patient/index-copy';

function RoutesAdmin() {
  return (
    <>
      <Route path="/inicio/admin" component={Dashboard} isPrivate />
      <Route path="/consultas/admin" component={Graphics} isPrivate />
      <Route path="/pacientes/admin" component={AppointmentsPatientADMIN} isPrivate />
      <Route path="/especialistas/admin" component={AppointmentsSpecADMIN} isPrivate />
      <Route path="/resumo/admin" component={Patients} isPrivate />
      <Route path="/perfil/admin" component={Settings} isPrivate />
    </>
  );
}

export default RoutesAdmin;
