import { useAuth } from '../context/useAuth';

export const LoginDuo = () => {
  const { signOut } = useAuth();


  return (
    <div className="hero bg-base-200">
      <div className="flex">
        <div className="grid place-items-center">
          <h1 className="text-2xl font-bold">Logar como Paciente</h1>
          <a href="/login/cli">
            <button className="btn btn-primary" onClick={signOut}>
              Faça login
            </button>
          </a>
        </div>
        <div className="divider divider-horizontal">OU</div>
        <div className="grid place-items-center">
          <h1 className="text-2xl font-bold">Logar como Especialista</h1>
          <a href="/login/pro">
            <button className="btn btn-primary" onClick={signOut}>
              Faça login
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginDuo;
