/* eslint-disable prefer-const */
import moment from 'moment';

require('moment');

const splitTime = (startTime, endTime, interval) => {
  let result = [startTime.toString()];
  let time = moment(startTime).add(interval, 'm');
  while (time.isBetween(startTime, endTime, undefined, [])) {
    result.push(time.toString());
    time = time.add(interval, 'm');
  }
  return result;
};

export default splitTime;
