function TitleCardPayment({ title, topMargin }) {
  return (
    <div className={`w-full col-span-12 ${topMargin || ''}`}>
      <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-3 md:col-span-3 lg:col-span-4">
        <h2 className="font-bold text-2xl mb-6">{title}</h2>
        <h2 className="font-light text-xl">Compra de créditos</h2>
        <p>Adquirir créditos para realizar consultas como quiser!</p>
        <img
          src="/common/pro3.png"
          alt="doctor"
          className="absolute bottom-0 right-7 mobile:hidden"
          width={260}
        />
      </div>
    </div>
  );
}

export default TitleCardPayment;
