import { useEffect, useState } from "react";
import styles from "../component.module.css";
import DayView from "../DayView/index";


const WeekView = ({ initialDate, setSelectedDateOfNumber, setSelectedData }) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const dateSelectedReverse = `${selectedDate.get('M') + 1}/${selectedDate.get('D')}/${selectedDate.get('y')}`
  const onDateClick = (date) => {
    setSelectedDate(date);
  };

  setSelectedData(dateSelectedReverse)

  useEffect(() => {
    setSelectedDateOfNumber(selectedDate.day())
  },[selectedDate])


  const getMonthLabel = () => {
    const labels = [];
    const date = initialDate;

    labels.push(date.day(0).locale('pt-br').format("MMMM"));
    labels.push(date.locale('pt-br').format("MMMM"));
    labels.push(date.day(6).locale('pt-br').format("MMMM"));

    const uniqueLabels = labels.filter((v, i, a) => a.indexOf(v) === i);

    return uniqueLabels.join(" / ");
  };

  const buildDays = (start, end) => {
    const days = [];
    const dateDiffInDays = end.diff(start, "day");

    for (let i = 0; i <= dateDiffInDays; i++) {
      const current = start.add(i, "day");

      days.push(
        <DayView
          key={i}
          current={current}
          onDateClick={onDateClick}
          selectedDate={selectedDate}
        />
      );
    }

    return days;
  };

  const render = () => {
    const start = initialDate.day(0);
    const end = initialDate.day(6);

    return (
      <div className={styles.dateList}>
        <div className={styles.monthContainer}>
          <span className={styles.monthYearLabel}>{getMonthLabel()}</span>
          <div className={styles.daysContainer}>{buildDays(start, end)}</div>
        </div>

      </div>
    );
  };

  return <>{render()}</>;
};

export default WeekView;
