import React, { useEffect, useState } from 'react';
import CardSpecialist from '../../components/CardSpecialist/index';
import { supabase } from '../../../../data/supabase';
import { useAuth } from '../../../../shared/context/useAuth';

function Favorite() {
  const { patient } = useAuth();
  const [firstFavorite, setFirstFavorite] = useState([]);
  useEffect(() => {
    const firstFav = async () => {
      const { data: patients } = await supabase
        .from('patient_favorite')
        .select('list_favorite')
        .eq('id_patient', patient[0]?.id)
        .order('id', { ascending: false })
        .limit(1);
      if (patients) {
        setFirstFavorite(patients);
      }
    };

    firstFav();
  }, [JSON.stringify(patient), JSON.stringify(firstFavorite)]);

  const [favoritesPatient, setFavoritesPatient] = useState([]);

  // eslint-disable-next-line no-nested-ternary
  return favoritesPatient?.length !== 0 ? (
    <CardSpecialist
      specialistData={favoritesPatient}
      setFavoritesPatient={setFavoritesPatient}
    />
  ) : firstFavorite && firstFavorite[0]?.list_favorite?.length > 0 ? (
    <CardSpecialist
      specialistData={firstFavorite && firstFavorite[0]?.list_favorite}
      setFavoritesPatient={setFavoritesPatient}
    />
  ) : (
    (favoritesPatient &&
      favoritesPatient?.length === 0 &&
      firstFavorite[0]?.list_favorite?.length === 0) && (
      <div className="col-span-12 flex flex-grow items-center justify-center">
        <div className="rounded-lg bg-white p-8 text-center shadow-xl">
          <h1 className="mb-4 text-4xl font-bold">Nennhum favorito!</h1>
          <p className="text-gray-600">
            Adicione seus especialistas favoritos aqui.
          </p>
        </div>
      </div>
    )
  );
}

export default Favorite;
