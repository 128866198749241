import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import TitleCard from '../../../shared/components/Cards/TitleCard';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChart() {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      }
    },
  };

  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'];


  const data = {
    labels,
    datasets: [
      {
        label: 'Individual',
        data: labels.map(() => Math.random() * 1000 + 500),
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
      {
        label: 'Casal/Familia',
        data: labels.map(() => Math.random() * 1000 + 500),
        backgroundColor: 'rgba(53, 162, 235, 1)',
      },
    ],
  };

  return (
    <TitleCard title="Por categoria" topMargin="mt-2">
      <Bar options={options} data={data} />
    </TitleCard>

  )
}


export default BarChart
