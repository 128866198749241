/** Icons are imported separatly to reduce build time */
import React from 'react';

import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';
import CalendarDaysIcon from '@heroicons/react/24/outline/CalendarDaysIcon';
import Cog6ToothIcon from '@heroicons/react/24/outline/Cog6ToothIcon';
import UserIcon from '@heroicons/react/24/outline/UserIcon';
import {
  CreditCardIcon,
  CurrencyDollarIcon,
  HeartIcon as HeartOutLine,
  WalletIcon,
} from '@heroicons/react/24/outline';

const iconClasses = `h-6 w-6`;
const submenuIconClasses = 'h-5 w-5';

const routesAdmin = [
  {
    path: '/inicio/admin',
    icon: <Squares2X2Icon className={iconClasses} />,
    name: 'Inicio Admin',
  },

  {
    path: '/pacientes/admin', // url
    icon: <CalendarDaysIcon className={iconClasses} />, // icon component
    name: 'Todos Clientes', // name that appear in Sidebar
  },
  {
    path: '/especialistas/admin', // url
    icon: <CalendarDaysIcon className={iconClasses} />, // icon component
    name: 'Todos Especialistas', // name that appear in Sidebar
  },
  // {
  //   path: '/consultas/admin', // url
  //   icon: <CalendarDaysIcon className={iconClasses} />, // icon component
  //   name: 'Todas Consultas', // name that appear in Sidebar
  // },
  // {
  //   path: '/conteudo/admin', // url
  //   icon: <CalendarDaysIcon className={iconClasses} />, // icon component
  //   name: 'Gerencie Conteudos', // name that appear in Sidebar
  // },
  // {
  //   path: '/pagamentos/admin', // url
  //   icon: <CalendarDaysIcon className={iconClasses} />, // icon component
  //   name: 'Transações', // name that appear in Sidebar
  // },

  // {
  //   path: '/pagamento/admin', // url
  //   icon: <CreditCardIcon className={`${iconClasses} inline`} />, // icon component
  //   name: 'Pagamentos Admin', // name that appear in Sidebar
  //   submenu: [
  //     {
  //       path: '/pagamento/admin', // url
  //       icon: <CurrencyDollarIcon className={submenuIconClasses} />, // icon component
  //       name: 'Comprar Créditos', // name that appear in Sidebar
  //     },
  //     {
  //       path: '/historico/admin', // url
  //       icon: <WalletIcon className={submenuIconClasses} />, // icon component
  //       name: 'Histórico Pagamentos', // name that appear in Sidebar
  //     },
  //   ],
  // },

  // {
  //   path: '/con', // no url needed as this has submenu
  //   icon: <Cog6ToothIcon className={`${iconClasses} inline`} />, // icon component
  //   name: 'Settings', // name that appear in Sidebar
  //   submenu: [
  //     {
  //       path: '/perfil/admin', // url
  //       icon: <UserIcon className={submenuIconClasses} />, // icon component
  //       name: 'Profile', // name that appear in Sidebar
  //     },
  //   ],
  // },
];

export default routesAdmin;
