function TitleCardSchedule({ title, children, topMargin }) {
  return (
    <div className={`w-full col-span-12 ${topMargin || ""}`}>

      <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-3 md:col-span-3 lg:col-span-4">
        <h2 className="font-bold text-2xl mb-6">{title}</h2>
        <h2 className="font-light text-xl">Planejamento</h2>
        <p>
          Planeje sua agenda para que seus pacientes possa te encontrar!
        </p>
        <img
          src="/specialist/schedule.png"
          alt="doctor"
          className="absolute bottom-7 right-7"
          width={160}
        />
      </div>
    </div>

  )
}


export default TitleCardSchedule
