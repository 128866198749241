import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    addFavorite: (state, action) => {
      const hasFav = state.some((item) => {
        return item.id === action.payload;
      });
      // const test = false

      if(!hasFav) return [
          ...state,
          {
            id: action.payload,
            favorite: true
          }
        ]

      return state.filter((item) => item.id !== action.payload);
    },
  },
});

export const { addFavorite, removeFavorite } = favoriteSlice.actions;

export default favoriteSlice.reducer;
