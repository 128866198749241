/** Icons are imported separatly to reduce build time */
import React from 'react';

import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';
import CalendarDaysIcon from '@heroicons/react/24/outline/CalendarDaysIcon';
import Cog6ToothIcon from '@heroicons/react/24/outline/Cog6ToothIcon';
import UserIcon from '@heroicons/react/24/outline/UserIcon';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';

const iconClasses = `h-6 w-6`;
const submenuIconClasses = 'h-5 w-5';

const routesSpecialists = [
  {
    path: '/inicio/pro',
    icon: <Squares2X2Icon className={iconClasses} />,
    name: 'Inicio',
  },
  // {
  //   path: "/app/transactions", // url
  //   icon: <CurrencyDollarIcon className={iconClasses} />, // icon component
  //   name: "Transactions", // name that appear in Sidebar
  // },
  // {
  //   path: "/app/charts", // url
  //   icon: <ChartBarIcon className={iconClasses} />, // icon component
  //   name: "Analytics", // name that appear in Sidebar
  // },
  {
    path: '/agendamentos/pro', // url
    icon: <CalendarDaysIcon className={iconClasses} />, // icon component
    name: 'Agendamentos', // name that appear in Sidebar
  },

  // {
  //   path: "", // no url needed as this has submenu
  //   icon: <DocumentDuplicateIcon className={`${iconClasses} inline`} />, // icon component
  //   name: "Pages", // name that appear in Sidebar
  //   submenu: [
  //     {
  //       path: "/login",
  //       icon: <ArrowRightOnRectangleIcon className={submenuIconClasses} />,
  //       name: "Login",
  //     },
  //     {
  //       path: "/register", // url
  //       icon: <UserIcon className={submenuIconClasses} />, // icon component
  //       name: "Register", // name that appear in Sidebar
  //     },
  //     {
  //       path: "/forgot-password",
  //       icon: <KeyIcon className={submenuIconClasses} />,
  //       name: "Forgot Password",
  //     },
  //     {
  //       path: "/app/blank",
  //       icon: <DocumentIcon className={submenuIconClasses} />,
  //       name: "Blank Page",
  //     },
  //     {
  //       path: "/app/404",
  //       icon: <ExclamationTriangleIcon className={submenuIconClasses} />,
  //       name: "404",
  //     },
  //   ],
  // },
  {
    path: '/con', // no url needed as this has submenu
    icon: <Cog6ToothIcon className={`${iconClasses} inline`} />, // icon component
    name: 'Configurações', // name that appear in Sidebar
    submenu: [
      {
        path: '/perfil/pro', // url
        icon: <UserIcon className={submenuIconClasses} />, // icon component
        name: 'Perfil', // name that appear in Sidebar
      },
      // {
      //   path: "/app/settings-billing",
      //   icon: <WalletIcon className={submenuIconClasses} />,
      //   name: "Billing",
      // },
      // {
      //   path: "/app/settings-team", // url
      //   icon: <UsersIcon className={submenuIconClasses} />, // icon component
      //   name: "Team Members", // name that appear in Sidebar
      // },
    ],
  },
  {
    path: '/legal/pro', // url
    icon: <ShieldCheckIcon className={iconClasses} />, // icon component
    name: 'Legal', // name that appear in Sidebar
  },
  // {
  //   path: "", // no url needed as this has submenu
  //   icon: <DocumentTextIcon className={`${iconClasses} inline`} />, // icon component
  //   name: "Documentation", // name that appear in Sidebar
  //   submenu: [
  //     {
  //       path: "/app/getting-started", // url
  //       icon: <DocumentTextIcon className={submenuIconClasses} />, // icon component
  //       name: "Getting Started", // name that appear in Sidebar
  //     },
  //     {
  //       path: "/app/features",
  //       icon: <TableCellsIcon className={submenuIconClasses} />,
  //       name: "Features",
  //     },
  //     {
  //       path: "/app/components",
  //       icon: <CodeBracketSquareIcon className={submenuIconClasses} />,
  //       name: "Components",
  //     },
  //   ],
  // },
];

export default routesSpecialists;
