import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { supabase } from '../../../data/supabase';

function usePayment() {
  const [products, setProducts] = useState([]);
  const [amount, setAmount] = useState(0);
  const [user, setUser] = useState({});

  const getProducts = async () => {
    axios
      .get('https://app-back-embrace.vercel.app/api/products')
      .then((response) => setProducts(response.data))
      .catch((error) => {
        console.log(error);
      });
  };



  const getProfile = async (user) => {
    try {
      const { data: patient } = await supabase
        .from('patient')
        .select()
        .eq('id_user', user.id);

      if (patient) {
        return patient;
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        setUser(user);
        return await getProfile(user);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getLastAmountBalance = async (patientId) => {
    const data = await supabase
      .from('balance')
      .select('amount')
      .eq('patient_id', patientId)
      .order('id', { ascending: false })
      .limit(1);

    setAmount(data?.data[0]?.amount);
    return data?.data[0]?.amount;

  };

  // Call API to update schedule settings changes
  const balanceUpdate = async (balance) => {
    try {
      const patient = await getUser();
      const lastAmountBalance = await getLastAmountBalance(patient[0]?.id);
      const upsertData = {
        patient_id: patient[0]?.id,
        amount: lastAmountBalance ? lastAmountBalance + balance.session.amount_total / 100 : balance.session.amount_total / 100,
      };
      await supabase.from('balance').insert(upsertData).select();

      toast.success('Crédito adicionado com sucesso!');
    } catch (error) {
      toast.error('Erro ao creditar!');
    }
  };


  useEffect(() => {
    const getBalance = async () => {
      const patient = await getUser();
      if (patient?.length > 0) {
        getLastAmountBalance(patient[0]?.id);
      }
    };
    getBalance();
    getProducts();
  }, []);

  return {
    products,
    balanceUpdate,
    amount,
    user,
    getUser,
  };
}

export default usePayment;
