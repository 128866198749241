import { useEffect } from 'react';
import { supabase } from '../../data/supabase';
import { useAuth } from '../context/useAuth';

export const Sucess = () => {
  const { session, signOut } = useAuth();
  const typeUser = window.location.pathname.split('/')[2];

  const updateUserData = async () => {
    const updates = {
      role: typeUser,
      email: session.user.email,
      name: session.user.user_metadata.name,
      id_user: session.user.id,
      avatar_url: session.user.user_metadata.picture,
    };
    await supabase
      .from(typeUser === 'cli' ? 'patient' : 'specialist')
      .insert(updates)
      .eq(updates.id)
      .select();
  };

  useEffect(() => {
    updateUserData(session?.id);
  }, []);

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Usuario cadastro com sucesso</h1>
          <a href={`/login/${typeUser}`}>
            <button className="btn btn-primary" onClick={signOut}>
              Faça login
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sucess;
